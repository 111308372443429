import { CommonModule } from '@angular/common';
import {
  ApplicationRef,
  Compiler,
  DoBootstrap,
  Injector,
  NgModule,
  NgModuleFactory,
  Type,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { routes } from './app.routing';
import { CoreModule } from './modules/core/core.module';
import { modules } from './modules/lazy-modules';
import { SharedModule } from './modules/shared/shared.module';

@NgModule({
  imports: [
    BrowserModule,
    RouterModule.forChild(routes),
    CommonModule,
    CoreModule,
    SharedModule,
  ],
})
export class AppModule implements DoBootstrap {
  constructor(private injector: Injector, private compiler: Compiler) {}

  ngDoBootstrap(appRef: ApplicationRef): void {
    this.bootstrapComponents(appRef);
  }

  bootstrapComponents(appRef: ApplicationRef) {
    const widgets = document.querySelectorAll('[data-module-name]');
    for (const i in widgets) {
      if (widgets.hasOwnProperty(i)) {
        const widget = widgets[i];
        const moduleName = widget.getAttribute('data-module-name');
        if (moduleName) {
          const module = modules.find((x) => x.name === moduleName);
          if (module) {
            this.loadModule(module.module, widget, appRef);
          }
        }
      }
    }
  }

  loadModule(path: any, widget: any, appRef: ApplicationRef) {
    (path() as Promise<NgModuleFactory<any> | Type<any>>)
      .then((elementModuleOrFactory) => {
        if (elementModuleOrFactory instanceof NgModuleFactory) {
          // if ViewEngine
          return elementModuleOrFactory;
        } else {
          try {
            // if Ivy
            return this.compiler.compileModuleAsync(elementModuleOrFactory);
          } catch (err) {
            throw err;
          }
        }
      })
      .then((moduleFactory) => {
        try {
          const ngModuleRef = moduleFactory.create(this.injector);
          ngModuleRef.injector
            .get('components')
            .forEach((components: Type<{}>[]) => {
              components.forEach((component: Type<{}>) => {
                const compFactory =
                  ngModuleRef.componentFactoryResolver.resolveComponentFactory(
                    component
                  );
                if (widget.localName === compFactory.selector) {
                  appRef.bootstrap(compFactory, widget);
                }
              });
            });
        } catch (err) {
          throw err;
        }
      });
  }
}
