import { Routes } from '@angular/router';

export const routes: Routes = [
  {
    loadChildren: () => import('./modules/swiperBanner/swiperBanner.module').then(m => m.SwiperBannerModule)
  },
  {
    loadChildren: () => import('./modules/microescapes/microescapes.module').then(m => m.MicroescapesModule)
  }, 
  {
    loadChildren: () => import('./modules/bannerDetails/bannerDetails.module').then(m => m.BannerDetailsModule)
  },
  {
    loadChildren: () => import('./modules/productPlans/productPlans.module').then(m => m.ProductPlansModule)
  },
  {
    loadChildren: () => import('./modules/modalPay/modalPay.module').then(m => m.ModalPayModule)
  },
  {
    loadChildren: () => import('./modules/rewards/rewards.module').then(m => m.RewardsModule)
  },
  {
    loadChildren: () => import('./modules/recompensasRuta/recompensas-ruta.module').then(m => m.RecompensasRutaModule)
  },
];
