export const modules = [
  {
    name: 'BannerSwiper',
    module: () => import('./swiperBanner/swiperBanner.module').then(m => m.SwiperBannerModule)
  },
  {
    name: 'Microescapes',
    module: () => import('./microescapes/microescapes.module').then(m => m.MicroescapesModule)
  },  
  {
    name: 'BannerDetails',
    module: () => import('./bannerDetails/bannerDetails.module').then(m => m.BannerDetailsModule)
  },
  {
    name: 'ProductPlans',
    module: () => import('./productPlans/productPlans.module').then(m => m.ProductPlansModule)
  },
  {
    name: 'ModalPay',
    module: () => import('./modalPay/modalPay.module').then(m => m.ModalPayModule)
  },
  {
    name: 'Rewards',
    module: () => import('./rewards/rewards.module').then(m => m.RewardsModule)
  },
  {
    name: 'RecompensasRuta',
    module: () => import('./recompensasRuta/recompensas-ruta.module').then(m => m.RecompensasRutaModule)
  },
];
